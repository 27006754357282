/* eslint-disable no-prototype-builtins */
import { NextRouter } from 'next/router'
import { isEmpty } from 'lodash'

// Libs
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'

interface IParams {
  query: IQueryString
}

interface IQueryString {
  category_id: string | string[] | undefined
  date: string | undefined
}

export const goToCalendarPage = (router: NextRouter, params?: IParams) => {
  if (!isEmpty(params) && params.hasOwnProperty('query') && params.query.hasOwnProperty('category_id') && params.query.hasOwnProperty('date')) {
    handleRoutePushWithQuery({ router, pathname: `${ROUTE_PATH.CALENDAR}`, query: params.query })
  } else {
    handleRoutePushWithQuery({ router, pathname: `${ROUTE_PATH.CALENDAR}` })
  }
}

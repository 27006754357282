import Service, { getCallApiFunction } from '../service'
import denormalizer from './denormalizer'
// import normalizer from './normalizer'

class FetchRecommendMerchantsApi extends Service {
  constructor() {
    super()
    this.name = 'FETCH_RECOMMEND_MERCHANTS'

    this.config = {
      url: `/v1/platform/recommend-merchants`,
      method: 'GET',
    }

    this.denormalizer = denormalizer
  }
}

export const fetchRecommendMerchants = () => getCallApiFunction(new FetchRecommendMerchantsApi())

import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

// Libs
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'
import handleLocationHrefDirect from '@/methods/url/handleLocationHrefDirect'

// Components
import Icon from '@/assets/icons'
import Button from '@/components/common/Button'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

interface EmptyProps {
  icon?: React.ReactNode
  title?: string
  description?: string
  hiddenButton?: boolean
  buttonText?: string
  buttonUrl?: string
  isExternalLink?: boolean
}

const Empty = (props: EmptyProps) => {
  const router = useRouter()
  const { t } = useTranslation()

  const {
    icon = <Icon.Empty />,
    title = t('Empty_Default_Title'),
    description = t('Empty_Default_Description'),
    hiddenButton = false,
    buttonText = t('Empty_Default_Button_Text'),
    buttonUrl = ROUTE_PATH.MALL,
    isExternalLink = false,
  } = props

  return (
    <div className={cx('empty')}>
      <div className={cx('empty-icon')}>{icon}</div>
      <div className={cx('empty__content')}>
        <h4 className={cx('empty__content-title')}>{title}</h4>
        <h4 className={cx('empty__content-description')}>{description}</h4>
      </div>
      {!hiddenButton && (
        <Button
          size='sm'
          onClick={() =>
            isExternalLink
              ? handleLocationHrefDirect({ router, url: buttonUrl, isOpen: true })
              : handleRoutePushWithQuery({ router, pathname: buttonUrl })
          }
        >
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export default Empty

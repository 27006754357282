type TSchemaType = 'Product' | 'BreadcrumbList' | 'WebSite' | 'Organization'

type TBreadcrumbList = {
  name: string
  url: string
  position: number
}[]

type TBasicSchemaOrg = {
  type: TSchemaType
  name: string
  description?: string
  image?: string | string[]
  url: string
  breadCrumbList: TBreadcrumbList
  potentialAction?: {
    '@type': 'SearchAction'
    target: string
    'query-input': 'required name=search_term_string'
  }
}

type TProductSchema = {
  image: string | string[]
  price: number
  lowPrice: number
  highPrice: number
} & TBasicSchemaOrg

// https://www.google.com/webmasters/markup-helper/u/0/?sjid=7094658595851150436-AP  這邊可以看到 Google 定義出來的 @type 種類
// https://search.google.com/test/rich-results/result 這邊可以測試自己的 schema 有什麼效果，只要把程式碼貼上

export const getProductSchemaOrg = ({ name, description, image, lowPrice, highPrice, price, breadCrumbList }: TProductSchema) => {
  return [
    {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: name,
      image: image,
      description: description,
      brand: 'TutorABC',
      sameAs: ['https://www.facebook.com/tutorabcshop', 'https://www.instagram.com/tutorabcshop'],
      offers: {
        price: price,
        lowPrice: lowPrice,
        highPrice: highPrice,
        priceCurrency: 'TWD',
      },
    },

    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadCrumbList.map((item) => {
        return {
          '@type': 'ListItem',
          position: item.position,
          name: item.name,
          item: item.url,
        }
      }),
    },
  ]
}

export const getSchemaOrg = ({ type, name, description = '', breadCrumbList = [], url = '' }: TBasicSchemaOrg) => {
  const schemaOrg = {
    '@context': 'https://schema.org',
    '@type': type,
    name: name,
    description: description,
    url: url,
    sameAs: ['https://www.facebook.com/tutorabcshop', 'https://www.instagram.com/tutorabcshop'],
  }
  const breadCrumbListSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadCrumbList.map((item) => {
      return {
        '@type': 'ListItem',
        position: item.position,
        name: item.name,
        item: item.url,
      }
    }),
  }

  return [schemaOrg, breadCrumbListSchema]
}

import React, { useEffect, useState } from 'react'
import classnames from 'classnames/bind'
import { isEmpty } from 'lodash'
import { useTranslation } from 'next-i18next'

// Libs
import useMedia from '@/hooks/useMedia'
import lottie from 'lottie-web'

// Styles
import styles from './style.module.scss'

// Lotties
import lottieWhiteSignalData from '@/assets/lotties/white-signal.json'
import lottiePlayButtonWithCircleData from '@/assets/lotties/play-button-with-circle.json'

// Constants
import { LIVE_ROOM_TAB_TYPE } from '@/constants/liveRoom'

const cx = classnames.bind(styles)

const TabButton = (props: any) => {
  const { handleCallback, index, selectedIndex, tabKey, label } = props

  const { t } = useTranslation()

  const [lottieAnimationInstance, setLottieAnimationInstance] = useState<any>(null)
  const [hasPlayAfterClick, setHasPlayAfterClick] = useState<boolean>(false)

  const iconRef = React.useRef<any>(null)

  const isSelected = index === selectedIndex

  const { isDesktop, isMobile, isTablet } = useMedia()

  useEffect(() => {
    const animationData = tabKey === LIVE_ROOM_TAB_TYPE.LIVE ? lottieWhiteSignalData : lottiePlayButtonWithCircleData

    const animation = lottie.loadAnimation({
      container: iconRef.current,
      animationData,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    })

    setLottieAnimationInstance(animation)
  }, [])

  const playLottieLoop = () => {
    if (!isDesktop) {
      return
    }

    if (!isEmpty(lottieAnimationInstance)) {
      lottieAnimationInstance.loop = true
      lottieAnimationInstance.play()
    }
  }

  const playLottieOnce = () => {
    if (!isEmpty(lottieAnimationInstance)) {
      lottieAnimationInstance.loop = false
      lottieAnimationInstance.play()
    }

    setHasPlayAfterClick(false)
  }

  const stopLottie = () => {
    if (!isEmpty(lottieAnimationInstance)) {
      lottieAnimationInstance.stop()
    }
  }

  const handleClick = () => {
    handleCallback()

    if (!hasPlayAfterClick) {
      setHasPlayAfterClick(true)
    }
  }

  const handleMouseLeave = () => {
    stopLottie()

    if (hasPlayAfterClick) {
      playLottieOnce()
    }
  }

  useEffect(() => {
    if (isTablet || isMobile) {
      if (!isEmpty(lottieAnimationInstance) && hasPlayAfterClick) {
        playLottieOnce()
      }
    }
  }, [lottieAnimationInstance, hasPlayAfterClick])

  return (
    <div
      onClick={handleClick}
      onMouseOver={playLottieLoop}
      onMouseLeave={handleMouseLeave}
      className={cx('tab-button', { 'tab-button--selected': isSelected })}
    >
      <div className={cx('tab-button__wrapper')}>
        <div ref={iconRef} className={cx('tab-button__icon')}></div>
        <div className={cx('tab-button__title')}>{t(label)}</div>
      </div>
    </div>
  )
}

export default TabButton

import type { GetServerSideProps, NextPage } from 'next'
import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

// Libs
import { fetchLiveList } from '@/api/fetchLiveList'
import { getSchemaOrg } from '@/methods/handleSchemaOrg'

// Components
import LiveComponent from '@/components/pages/Live'

// Constants
import { liveTabsData } from '@/constants/liveRoom'
import { ROUTE_PATH } from '@/constants/routePath'
import { envConfigs } from '@/constants/config'
import { DEFAULT_LOCALE } from '@/constants/defaultLocale'

// Styles

// Variables
const Live: NextPage = ({ pageProps }: any) => {
  const { liveDataSSR } = pageProps

  const router = useRouter()

  const currentLocale = router.locale === 'default' ? DEFAULT_LOCALE : router.locale

  const { t } = useTranslation()
  const schema = getSchemaOrg({
    type: 'WebSite',
    name: t('Meta_Title_Live'),
    description: t('Meta_Description_Live'),
    url: `${envConfigs.domainUrl}/${currentLocale}${ROUTE_PATH.LIVE}`,
    breadCrumbList: [
      {
        name: t('Header_Mall'),
        position: 1,
        url: `${envConfigs.domainUrl}/${currentLocale}${ROUTE_PATH.MALL}`,
      },
    ],
  })

  return (
    <>
      <Head>
        <title>{t('Meta_Title_Live')}</title>
        <link rel='shortcut icon' href='/favicon.ico' />
        <link rel='canonical' href={`${envConfigs.domainUrl}/${currentLocale}${ROUTE_PATH.LIVE}`} />
        <meta name='title' content={t('Meta_Title_Live')} />
        <meta name='description' key='meta-description' content={t('Meta_Description_Live')} />
        <meta property='og:title' key='title' content={t('Meta_Title_Live')} />
        <meta property='og:description' key='description' content={t('Meta_Description_Live')} />
        <meta property='og:type' key='type' content='website' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='628' />
        <meta property='og:image' content='/images/ogimage-main.png' />
        <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
      </Head>

      <LiveComponent liveDataSSR={liveDataSSR} />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ res, query, locale }) => {
  const tab = Number(query?.tab) || 0

  const liveData = await fetchLiveList({ pageSize: 20, pageNo: 1, liveTypes: liveTabsData[tab]?.value.join(',') })

  res.setHeader('Cache-Control', 'public, s-maxage=7200, stale-while-revalidate=7260')

  return {
    props: {
      liveDataSSR: liveData,
      ...(await serverSideTranslations(locale as string, ['common'])),
    },
  }
}

export default Live

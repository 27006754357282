import React from 'react'
import classnames from 'classnames/bind'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'next-i18next'

// Libs
import { useRecommendMerchants } from './hooks/useRecommendMerchants'

// Components
import ChoiceBrandNavItem from './components/ChoiceBrandNavItem'
import ChoiceBrandNavItemSkeleton from './components/ChoiceBrandNavItem/skeleton'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

const ChoiceBrandNav = () => {
  const { recommendMerchantsData, isRecommendMerchantsDataSuccess } = useRecommendMerchants()
  const { t } = useTranslation()

  return (
    <div className={cx('choice-brand-nav')}>
      <div className={cx('container')}>
        <div className={cx('choice-brand-nav__title')}>{t('Choice_Merchant_Title')}</div>
        <div className={cx('choice-brand-nav__list')}>
          <Swiper
            slidesPerView='auto'
            spaceBetween={10}
            slideToClickedSlide
            grabCursor
            breakpoints={{
              710: {
                spaceBetween: 42,
              },
            }}
          >
            {isRecommendMerchantsDataSuccess ? (
              recommendMerchantsData?.length > 0 &&
              recommendMerchantsData?.map((merchantItem: any) => (
                <SwiperSlide key={merchantItem?.id} style={{ width: 'auto' }}>
                  <ChoiceBrandNavItem {...merchantItem} />
                </SwiperSlide>
              ))
            ) : (
              <ChoiceBrandNavItemSkeleton count={10} />
            )}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default ChoiceBrandNav

import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

type ParamsType = {
  sessionSn: string
  memberInfoId?: string
  mode?: number
}

// 直播間 websocket 或串流的相關資訊
class FetchRoomLivingDataApi extends Service {
  constructor(params: ParamsType) {
    super()
    this.name = 'FETCH_ROOM_LIVING_DATA'

    this.config = {
      url: `/v1/room/live`,
      method: 'GET',
      params,
    }
  }
}

export const fetchRoomLivingData = (params: ParamsType) => getCallApiFunction(new FetchRoomLivingDataApi(params))

import { useQuery } from 'react-query'
import { useRouter } from 'next/router'

// Libs
import { fetchRecommendMerchants } from '@/api/fetchRecommendMerchants'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

// Types

export const useRecommendMerchants = () => {
  const router = useRouter()
  const { locale } = router
  const { data, isSuccess } = useQuery([QUERY_KEY.FETCH_RECOMMEND_MERCHANTS, { locale }], () => fetchRecommendMerchants())

  return { recommendMerchantsData: data, isRecommendMerchantsDataSuccess: isSuccess }
}

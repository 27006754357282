import { useQuery } from 'react-query'

// Libs
import { fetchBannerList } from '@/api/fetchBannerList'

// Constants
import { QUERY_KEY } from '@/constants/queryKey'

// Types

export const useBannerList = (params: any) => {
  const bannerList = useQuery([QUERY_KEY.FETCH_BANNER_LIST, { ...params }], () => fetchBannerList(params))

  return { bannerList }
}

import React from 'react'
import classnames from 'classnames/bind'
import { useRouter } from 'next/router'

// Libs
import { handleGtmClickMerchant } from '@/methods/handleGtmEvent'

// Constants
import { ROUTE_PATH } from '@/constants/routePath'
import { GTM_LOCATION } from '@/constants/gtmEvent'

// Components
import Image from '@/components/common/Image'

// Methods
import handleRoutePushWithQuery from '@/methods/url/handleRoutePushWithQuery'

// Styles
import styles from './style.module.scss'

const cx = classnames.bind(styles)

interface IProps {
  id: number
  merchantId: string
  memberInfoId: string
  brandLogo: string
  brandName: string
}

const ChoiceBrandNavItem = (props: IProps) => {
  const { merchantId, memberInfoId, brandLogo, brandName } = props
  const router = useRouter()

  const goToMerchantInfoPage = (memberInfoId: string) => {
    handleRoutePushWithQuery({ router, pathname: `${ROUTE_PATH.USER}/${memberInfoId}` })
    handleGtmClickMerchant({
      location: GTM_LOCATION.LIVE,
      merchantDetail: {
        merchantId: merchantId,
        merchantName: brandName,
      },
    })
  }

  return (
    <div onClick={() => goToMerchantInfoPage(memberInfoId)} className={cx('choice-brand-nav__item')}>
      <div className={cx('choice-brand-nav__item-image')}>
        <Image src={brandLogo} alt={brandName} fill sizes='100px' />
        <div className={cx('choice-brand-nav__item-image-mask')}></div>
      </div>
      <div className={cx('choice-brand-nav__item-title')}>{brandName}</div>
    </div>
  )
}
export default ChoiceBrandNavItem

import Service, { getCallApiFunction } from '../service'
// import denormalizer from './denormalizer'
// import normalizer from './normalizer'

export interface IParams {
  location: string
}

class FetchBannerListApi extends Service {
  constructor(params: IParams) {
    super()
    this.name = 'FETCH_BANNER_LIST'

    this.config = {
      url: `/v1/platform/banners`,
      method: 'GET',
      params: { ...params },
    }
  }
}

export const fetchBannerList = (params: IParams) => getCallApiFunction(new FetchBannerListApi(params))

import React from 'react'
import classnames from 'classnames/bind'

// Components
import Skeleton from 'react-loading-skeleton'

// Styles
import styles from './style.module.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const cx = classnames.bind(styles)

const ChoiceBrandNavItemSkeleton = ({ count = 1 }: { count: number }) => {
  return (
    <div className={cx('choice-brand-nav')}>
      {Array.from(Array(count)).map((_, index) => (
        <div className={cx('choice-brand-nav__item')} key={index}>
          <div className={cx('choice-brand-nav__item-image')}>
            <Skeleton borderRadius={10} style={{ display: 'inline-block', width: '100%', height: '100%' }} />
          </div>
          <Skeleton
            containerClassName={cx('choice-brand-nav__item-title')}
            height={'100%'}
            width={60}
            borderRadius={50}
            style={{ display: 'inline-block' }}
          />
        </div>
      ))}
    </div>
  )
}
export default ChoiceBrandNavItemSkeleton
